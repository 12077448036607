var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"AmbulanciasTrasladosForaneos empresas__inner container mt24 mb24"},[_c('ButtonBackHome'),_vm._m(0),_c('v-card',{attrs:{"flat":"","disabled":_vm.cargando,"loading":_vm.cargando}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Buscar","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"upper",attrs:{"headers":_vm.headers,"items":_vm.ordenescompra,"search":_vm.search,"loading":_vm.cargando,"loading-text":"Cargando..."},scopedSlots:_vm._u([{key:"item.folio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_com.padStart(6, '0'))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._doDiaMes(item.created_com))+" ")]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm._handleEstatus(item.estatus_com,item.fecha_pago_com,'color')}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm._handleEstatus(item.estatus_com,item.fecha_pago_com,'text'))}})])]}},{key:"item.factura",fn:function(ref){
var item = ref.item;
return [(item.pdf_com)?_c('v-btn',{attrs:{"icon":"","href":_vm.$files+item.pdf_com,"target":"_blank"}},[_c('v-icon',[_vm._v("picture_as_pdf")])],1):_vm._e(),(item.xml_com)?_c('v-btn',{attrs:{"icon":"","href":_vm.$files+item.xml_com,"target":"_blank"}},[_c('v-icon',[_vm._v("code")])],1):_vm._e()]}},{key:"item.pago",fn:function(ref){
var item = ref.item;
return [(item.comprobante_pago_com)?_c('v-btn',{attrs:{"icon":"","href":_vm.$files+item.comprobante_pago_com,"target":"_blank"}},[_c('v-icon',[_vm._v("task")])],1):_vm._e()]}},{key:"item.pagada",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fecha_pago_com ? _vm._doDiaMes(item.fecha_pago_com) : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":"","to":'/empresas/ordendecompra/'+item.id}},[_vm._v(" Ver más "),_c('v-icon',[_vm._v("chevron_right")])],1)]}},{key:"item.complemento",fn:function(ref){
var item = ref.item;
return [(item.complemento_pago_pdf_com)?_c('v-btn',{attrs:{"icon":"","href":_vm.$files+item.complemento_pago_pdf_com,"target":"_blank"}},[_c('v-icon',[_vm._v("picture_as_pdf")])],1):_vm._e(),(item.complemento_pago_xml_com)?_c('v-btn',{attrs:{"icon":"","href":_vm.$files+item.complemento_pago_xml_com,"target":"_blank"}},[_c('v-icon',[_vm._v("code")])],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"empresas__header"},[_c('h2',{staticClass:"empresas_home__heading heading"},[_vm._v("Ordenes de compra")]),_c('div',{staticClass:"empresas_home__text"},[_vm._v("Aquí podrás encontrar todas las ordenes de compra que te hayamos realizado junto a toda su información relacionada. ")])])}]

export { render, staticRenderFns }