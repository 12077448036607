<template>
  <section class="AmbulanciasTrasladosForaneos empresas__inner container mt24 mb24">
    <ButtonBackHome />
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">Ordenes de compra</h2>
      <div class="empresas_home__text">Aquí podrás encontrar todas las ordenes de compra que te hayamos realizado junto a toda su información relacionada.
        <!-- <br><span style="background:#ecb731">Recuerda que todo servicio de ambulancias deberá ser pagado por lo menos con 48 horas de anticipación para poder apoyarte.</span> -->
      </div>
    </header>
    <v-card
      flat
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="ordenescompra"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.folio="{ item }">
          {{item.id_com.padStart(6, '0')}}
        </template>
        <template v-slot:item.fecha="{ item }">
          {{ _doDiaMes(item.created_com) }}
        </template>
        <!-- <template v-slot:item.origen="{ item }">
          {{item.lugar_inicio_eva.description}}
        </template> -->
        <template v-slot:item.estatus="{ item }">
          <v-chip
            small
            :color="_handleEstatus(item.estatus_com,item.fecha_pago_com,'color')"
          >
            <span v-html="_handleEstatus(item.estatus_com,item.fecha_pago_com,'text')" />
          </v-chip>
        </template>
        <template v-slot:item.factura="{ item }">
          <v-btn
            v-if="item.pdf_com"
            icon
            :href="$files+item.pdf_com"
            target="_blank"
          >
            <v-icon>picture_as_pdf</v-icon>
          </v-btn>
          <v-btn
            v-if="item.xml_com"
            icon
            :href="$files+item.xml_com"
            target="_blank"
          >
            <v-icon>code</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.pago="{ item }">
          <v-btn
            v-if="item.comprobante_pago_com"
            icon
            :href="$files+item.comprobante_pago_com"
            target="_blank"
          >
            <v-icon>task</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.pagada="{ item }">
          {{item.fecha_pago_com ? _doDiaMes(item.fecha_pago_com) : ''}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            color="primary"
            small
            :to="'/empresas/ordendecompra/'+item.id"
          >
            Ver más
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.complemento="{ item }">
          <v-btn
            icon
            v-if="item.complemento_pago_pdf_com"
            :href="$files+item.complemento_pago_pdf_com"
            target="_blank"
          >
            <v-icon>picture_as_pdf</v-icon>
          </v-btn>
          <v-btn
            icon
            v-if="item.complemento_pago_xml_com"
            :href="$files+item.complemento_pago_xml_com"
            target="_blank"
          >
            <v-icon>code</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </section>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";

export default {
  name: "AmbulanciasListado",
  mixins: [standard, forms, dates],
  components: {},
  data() {
    return {
      cargando: false,
      session: false,
      showTitle: true,
      search: "",
      headers: [
        { text: "Folio", value: "folio" },
        { text: "Fecha", value: "fecha" },
        { text: "Estatus", value: "estatus" },
        { text: "Factura CFDI", value: "factura" },
        { text: "Pagada", value: "pagada" },
        { text: "Comprobante de pago", value: "pago" },
        { text: "Complemento de pago", value: "complemento" },
        { text: "", value: "actions" },
      ],
      ordenescompra: [],
    };
  },
  methods: {
    _getOrdenesCompra() {
      this._getThings("ordenescompra", "ordenescompra");
    },
    _handleEstatus(estatus, pago, formato) {
      "confirmada", "comprada";
      if (pago) {
        return formato == "text" ? "Pagada" : "#00DC7D";
      }
      switch (estatus) {
        case "confirmada":
          return formato == "text" ? "Solicitada" : "#FFC11E";
        case "comprada":
          return formato == "text" ? "Recibida" : "#1771F1";
        case "solicitada":
          return formato == "text" ? "Por solicitar" : "#FFC11E";
        default:
          return formato == "text" ? estatus : "transparent";
      }
    },
  },
  created() {
    // this._handleVisibility();
    if (!this.$session.has("proveedor")) this.$router.push("/empresas");
    this._getOrdenesCompra();
  },
  watch: {
    $route(a, b) {},
  },
};
</script>